import React from "react";
import {
  ComponentProps,
  ComponentWidth,
  KontentRichText,
} from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";
import * as styles from "./FiftyFiftyText.module.css";

interface Props extends ComponentProps, ComponentWidth {
  subhead: string;
  firstColumn: KontentRichText;
  secondColumn: KontentRichText;
  fontHex?: string;
  backgroundHex?: string;
}

export const FiftyFiftyText: React.FC<Props> = ({
  subhead,
  firstColumn,
  secondColumn,
  fontHex,
  backgroundHex,
  width,
  ...props
}) => {
  const containerClassName = [styles.fiftyfiftytext];
  if (width === "contained") {
    containerClassName.push(styles.contained);
  }

  const authorStyles =
    backgroundHex || fontHex
      ? {
          backgroundColor: backgroundHex,
          color: fontHex,
        }
      : undefined;

  return (
    <SectionWrapper {...props}>
      <div className={containerClassName.join(" ")} style={authorStyles}>
        <div className={styles.content}>
          {subhead && <h2>{subhead}</h2>}
          <div className={styles.columns}>
            <CustomRichTextElement content={firstColumn} />
            <CustomRichTextElement content={secondColumn} />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
