import React from "react";
import { graphql } from "gatsby";
import { FiftyFiftyText } from "./FiftyFiftyText";
import {
  KontentColorConfig,
  KontentComponentLayout,
  KontentComponentSettings,
  KontentRichText,
  StringValue,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { getColor } from "../../../lib/color";

interface Props
  extends KontentComponentSettings,
    KontentComponentLayout,
    KontentColorConfig {
  subhead: StringValue;
  first_column: KontentRichText;
  second_column: KontentRichText;
}

const FiftyFiftyTextContainer: React.FC<Props> = ({
  subhead,
  first_column,
  second_column,
  component_layout__component_width,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  id,
  background_color,
  font_color,
}) => {
  return (
    <FiftyFiftyText
      id={id}
      width={getMultiChoice(component_layout__component_width)}
      anchor={component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
      backgroundHex={getColor(background_color)}
      fontHex={getColor(font_color)}
      subhead={subhead.value}
      firstColumn={first_column}
      secondColumn={second_column}
    ></FiftyFiftyText>
  );
};

export default FiftyFiftyTextContainer;

export const fragmentFiftyFiftyText = graphql`
  fragment FiftyFiftyText on kontent_item_component___fifty_fifty_text {
    elements {
      subhead {
        value
      }
      first_column {
        ...kontentSimpleRichText
      }
      second_column {
        ...kontentSimpleRichText
      }
      background_color {
        ...brandColor
      }
      font_color {
        ...brandColor
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
      component_layout__component_width {
        value {
          codename
        }
      }
    }
  }
`;
